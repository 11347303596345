<template>
  <div>
    <vs-popup title="Lista de SIMCARDS Selecionados" :active.sync="popUpSelecao.popupActive">
          <vs-alert color="danger" :active.sync="popUpSelecao.exibirMensagem" closable icon-pack="feather" close-icon="icon-x" class="mb-3" icon="icon-info">
              {{popUpSelecao.mensagem}}
          </vs-alert> 
          <vs-table :data="listaSimcardsSelecionados" noDataText="Nenhum SIMCARD selecionado." stripe search>
            <template slot="header">
                <h6 class="mr-auto" v-if="listaSimcardsSelecionados.length > 0 "> {{listaSimcardsSelecionados.length}} simcards selecionado(s)</h6>                                   
            </template>
            <template slot="thead">
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">Número</span></vs-th>
                <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">Operadora</span></vs-th>            
                <vs-th><span class="w-full" style="text-align:center !important;"> Empresa </span></vs-th>                            
                <vs-th></vs-th>   
                <vs-th></vs-th>   
            </template>

            <template slot-scope="{data}"> 
                <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                    <vs-td :data="tr.numeroGSM"  style="padding: 1px;">
                        <small>{{tr.numeroGSM}}</small>                   
                    </vs-td>
                    <vs-td :data="tr.operadoraGSM"  style="padding: 1px;" class="text-left">
                        <small>{{tr.operadoraGSM}}</small>
                    </vs-td>   
                    <vs-td :data="tr.nomeEmpresa"  style="padding: 1px;" class="text-center">
                        <small>{{tr.nomeEmpresa.substring(0, Math.min(30, tr.nomeEmpresa.length))}}</small>
                    </vs-td> 
                    <vs-td   style="padding: 1px;" class="text-left">
                        
                        <small>{{tr.resultadoOperacao}}</small>
                    </vs-td>   
                    <vs-td  style="padding: 1px;">
                        <vs-button size="small" color="danger" icon-pack="feather" icon="icon-minus" @click="removerSimcard(tr)"></vs-button>             
                    </vs-td>
                </vs-tr>
            </template>
          </vs-table> 
          <div class="w-full flex justify-center">
            <vs-button title="Excluir SIMCARDs" size="small" @click="excluirTodos" icon="delete" color="danger" :disabled="!listaSimcardsSelecionados.length">Excluir Simcards</vs-button>            
            <vs-button title="Limpar Lista" size="small" @click="listaSimcardsSelecionados = []" class="ml-2" icon="clear" color="primary" :disabled="!listaSimcardsSelecionados.length">Limpar Lista</vs-button>            
          </div>  
    </vs-popup>
    <vs-popup title="Pesquisar SIMCARDS" :active.sync="popUpFiltroMultiplo.popupActive">
        <div class="vx-row">                
            <div class="vx-col w-full">
                <small>Lista de Números GSM.</small>
                <small>Cada linha deve conter um número apenas</small>
                <vs-textarea v-model="popUpFiltroMultiplo.filtroListaSimcards" counter="4096" rows="10"/>
            </div>
        </div>

        <vs-button title="Pesquisar Lista de SIMCARDs" size="small" @click="fetchLista(true)" icon="search" color="primary" :disabled="!popUpFiltroMultiplo.filtroListaSimcards">Pesquisar</vs-button>            
        
    </vs-popup>
    
    <vx-card class="mb-1">
       <vs-row vs-type="flex" vs-justify="space-between" >
          <vs-col vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2" style="white-space:nowrap">Número GSM</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.numeroGSM" @keyup.enter="fetchLista()" />
          </vs-col>
          <vs-col vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2">ICCID</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.iccid" @keyup.enter="fetchLista()" />
          </vs-col>
         
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
            <div class="flex mt-4"> 
              <vx-tooltip text="Pesquisar pelos filtros">                
                <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista()"></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Pesquisar lote simcards pelo numero GSM">                
                <vs-button class="mr-2" color="success" type="filled" icon-pack="feather" icon="icon-search" @click="abrirPopUpFiltro"></vs-button>
              </vx-tooltip>
              <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
              <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro" v-if="isPermitirIncluir"></vs-button>                      
              <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-list" @click="routeImportarSimcards" v-if="isPermitirIncluir"></vs-button>          
              <vx-tooltip text="Adicionar todos os simcards listados">   
                <vs-button class="mr-2" color="primary" type="filled" icon="post_add" @click="adicionarTodos()" v-if="isAllowedToSave"></vs-button>             
              </vx-tooltip>
              <feather-icon icon="ArchiveIcon" class="cursor-pointer mr-2" :badge="listaSimcardsSelecionados.length" @click="abrirPopUpSelecao" />       
              <vx-tooltip text="Exportar para CSV">                
                <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>          
              </vx-tooltip>
            </div>
          </vs-col>
       </vs-row>
       <vs-row class="mt-2">          
          <vs-col vs-justify="flex-start" vs-align="center" vs-w="3"  v-if="isConceptOuConceptLab">         
              <label class="vs-input--label mr-2">Empresa</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.nomeEmpresa" @keyup.enter="fetchLista()" />
          </vs-col>
          <vs-col vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2">Cliente</label>
              <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.nomeCliente" @keyup.enter="fetchLista()" />
          </vs-col>
          <vs-col vs-justify="flex-start" vs-align="center" vs-w="3">         
              <label class="vs-input--label mr-2" style="white-space:nowrap">Operadora GSM</label>              
               <v-select                                               
                    :options="CONSTANTE_OPERADORAS_GSM" 
                    :reduce="r => r.data"                    
                    v-model="filtro.operadoraGSM"  
                    class="w-full vs-input--label">
                </v-select>  
          </vs-col>
          <vs-col vs-align="center" vs-w="3">         
            <div class="flex mt-5 justify-center w-full">
              <vx-tooltip text="Todos os SIMCARDS com data de ativação e sem data de desativação">                
                <vs-checkbox v-model="filtro.sohAtivos" class="w-full"><small>Ativos</small></vs-checkbox>
              </vx-tooltip>
              <vx-tooltip text="Todos os SIMCARDS COM data de desativação">                
                <vs-checkbox v-model="filtro.sohInativos" class="w-full"><small>Inativos</small></vs-checkbox>
              </vx-tooltip>
            </div>
            <div class="flex justify-center w-full">
              <vx-tooltip text="Todos os SIMCARDS não cadastrado em um módulo">                
                <vs-checkbox v-model="filtro.semModulos" class="w-full"><small>Sem módulos</small></vs-checkbox>
              </vx-tooltip>
              <vx-tooltip text="Todos os SIMCARDS cadastrados em módulo, mas o módulo não está ativo em nenhum veículo">                
                <vs-checkbox v-model="filtro.emModulosEmEstoque" class="w-full"><small>Em estoque</small></vs-checkbox>
              </vx-tooltip>
            </div>
          </vs-col>          
      </vs-row>
    </vx-card>
     
    <h6 class="mt-2 text-danger"> {{dadosDaGrid.length }} registros </h6>

    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-grid-table ag-grid-altura mt-2"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="24"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      :context="configuracaoGrid.context"
      @grid-ready="chamarMetodo"   
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import { CONSTANTE_OPERADORAS_GSM } from '@/constantesComboBox.js'
import BtnAggridComponentRenderer from './BtnAggridComponent.vue';
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'
import generico from "@/generico.js"


const URL_SERVLET = "/SimcardRest";
const NAME_ROUTE_EDIT = "cadastro-simcard-editar";

export default {
  components: {
    AgGridVue,
    BtnAggridComponentRenderer
  },
  data() {
    return {
      popUpFiltroMultiplo: {
        popupActive: false,
        filtroListaSimcards: null
      },
      popUpSelecao : {
          popupActive: false,
          exibirMensagem: false,
          mensagem: null          
      },
      filtro : {numeroGSM: null, nomeEmpresa: null, iccid: null, sohAtivos: false, semModulos:false, operadoraGSM: null, sohInativos: false, nomeCliente: null, emModulosEmEstoque: false},
      configuracaoGrid: { 
          context: null, 
          gridApi: null, gridOptions: {
              suppressRowClickSelection: true,
              onCellClicked: (e) => {
                  if (e.column.colId !== 'params') { // cell is from non-select column
                      e.node.setSelected(true);
                  }
              }              
          }, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [    
            { headerName: "#", valueGetter: "node.rowIndex + 1", width: 30,  cellStyle: function() { return {'font-size': '10px'}; }  },    
            { headerName: '' , field: 'numero', cellRendererFramework: BtnAggridComponentRenderer, colId: 'params', editable: false, width: 25},     
            { headerName: 'Empresa', field: 'nomeEmpresa', width: 75},                       
            { headerName: 'Numero', field: 'numeroGSM', width: 45 },           
            { headerName: 'Operadora', field: 'operadoraGSM', width: 50},          
            { headerName: 'ICCID', field: 'iccid', width: 75},                  
            { headerName: 'Módulo', field: 'codigoModulo', width: 75},                             
            { headerName: 'Ativação', field: 'dataInclusao', width: 45},           
            { headerName: 'Desativação', field: 'dataExclusao', width: 45},            
            { headerName: 'Valor', field: 'valorMensal', width: 25,
              valueGetter: function(params) {
                 return generico.formatarNumero(params.data.valorMensal);
              }
            },        
            { headerName: 'Empresa Proprietária', field: 'nomeEmpresaProprietaria', width: 75},           
            { headerName: 'Cliente', field: 'nomeCliente', width: 75},           
            
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0,
      listaSimcardsSelecionados: [],   
      posicaoCorrente: 0,
    }
  },  
  computed: {        
    isPermitirIncluir() {
        let idEmpresa = this.$store.state.AppActiveUser.idEmpresa;
        if (idEmpresa === 1) {
            return true;
        }

        return false;
    },
    isConceptOuConceptLab() {
        let idEmpresa = this.$store.state.AppActiveUser.idEmpresa;
        if (idEmpresa === 1 || idEmpresa === 40) {
            return true;
        }

        return false;
    },
    isAllowedToSave() {
       let perfilLogado = this.$store.state.AppActiveUser.perfil;
       if (perfilLogado === "CONFIGURADOR" || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {
            return false;	
       } 

       return true;
    },
  },
  created() {
      this.CONSTANTE_OPERADORAS_GSM = CONSTANTE_OPERADORAS_GSM;
  },
  methods: {
    abrirPopUpFiltro() {
        this.popUpFiltroMultiplo.popupActive = true;        
    },
    chamarMetodo(event){
        this.configuracaoGrid.gridApi = event.api;
        this.configuracaoGrid.gridColumnApi = event.columnApi;          
    },
    adicionarTodos() {
        for (var i= 0; i < this.dadosDaGrid.length; i++){
            if (this.listaSimcardsSelecionados.indexOf(this.dadosDaGrid[i]) === -1 && this.dadosDaGrid[i].numeroGSM) {
                this.listaSimcardsSelecionados.push(this.dadosDaGrid[i]);
            } 
        }
    },
    limparListaSelecao() {
        this.listaSimcardsSelecionados = null;
        this.listaSimcardsSelecionados = [];
    },
    abrirPopUpSelecao() {
        this.popUpSelecao.popupActive = true;        
    },
    adcionarSimcard(pItem) {
        if (this.listaSimcardsSelecionados.indexOf(pItem) === -1) {
            this.listaSimcardsSelecionados.push(pItem);
        } else {
            this.$vs.dialog({color:'danger', title: 'Atenção', text: 'OS já incluída', acceptText:'OK' }) 
        }
    },
    removerSimcard(pItem) {
        this.listaSimcardsSelecionados.splice(this.listaSimcardsSelecionados.indexOf(pItem), 1);
    },
    exportarParaCSV() {        
        var params = {columnSeparator: ';', fileName: 'Simcards.csv'};
        this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    }, 
    limparFiltros() {
      this.filtro =  {numeroGSM: null, nomeEmpresa: null, iccid: null, sohAtivos: false, semModulos:false, operadoraGSM: null, sohInativos: false, nomeCliente: null, emModulosEmEstoque: false};      
    },
    fetchLista(isLista = false) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      if (!isLista) {
        if (this.filtro.nomeEmpresa) {
          params.append('nomeEmpresa', this.filtro.nomeEmpresa);
        }

        if (this.filtro.numeroGSM) {
          params.append('filter1', this.filtro.numeroGSM);
        }

        if (this.filtro.iccid) {
          params.append('iccidPesquisa', this.filtro.iccid);
        }

        if (this.filtro.sohAtivos) {
          params.append('sohAtivos', this.filtro.sohAtivos);
        }

        if (this.filtro.sohInativos) {
          params.append('sohInativos', this.filtro.sohInativos);
        }

        if (this.filtro.operadoraGSM) {
          params.append('operadoraGSM', this.filtro.operadoraGSM);
        }

        if (this.filtro.semModulos) {
          params.append('semModulos', this.filtro.semModulos);
        }

        if (this.filtro.nomeCliente) {
          params.append('nomeCliente', this.filtro.nomeCliente);
        }

        if (this.filtro.emModulosEmEstoque) {
          params.append('emModulosEmEstoque', this.filtro.emModulosEmEstoque);
        }
      } else if (isLista && this.popUpFiltroMultiplo.filtroListaSimcards) {
        params.append('filtroListaSimcards', this.popUpFiltroMultiplo.filtroListaSimcards);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;         
              this.totalRegistros = this.dadosDaGrid.length;                              
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      this.$store.dispatch('setFiltroCadastroTecnicos', this.filtro);

      let self_ = this;

      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    onRowSelectedRegistro(evento) {
      this.$store.dispatch('setFiltroCadastroTecnicos', this.filtro);

      let self_ = this;
      let registro = evento.node.data;
      
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })

    },
    routeImportarSimcards() {      
      this.$router.push({
            name: "cadastro-simcard-importar"                     
      })
    },
    excluirTodos() {  
        this.$vs.loading();
        this.posicaoCorrente = 0;              
        this.$vs.loading();                          
        this.excluirProximo();          
    },
    excluirProximo(){        
        if (this.posicaoCorrente <= this.listaSimcardsSelecionados.length -1 ) {            
          this.excluir(this.listaSimcardsSelecionados[this.posicaoCorrente]);
        } else {         
          this.$vs.loading.close();        
        }        
    },
    excluir(simcard) {                
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('numeroGSM', simcard.numeroGSM);   
        params.append('idEmpresa', simcard.idEmpresa);    
        
        axios.post("/SimcardRest", params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {                
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {                    
                if (response.data.hasOwnProperty("error")) {                        
                    this.$set(simcard, 'resultadoOperacao', "Erro:" + response.data.error);
                } else {
                    this.$set(simcard, 'resultadoOperacao', "Sucesso: exclusão efetuada");
                }      
                 
                this.posicaoCorrente++;                    
                this.excluirProximo();
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
        
         
    },
  },
  beforeMount() {
     this.configuracaoGrid.context = { componentParent: this };    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();      
    if (this.$store.getters.filtroCadastroTecnicos) {
      this.filtro = JSON.parse(JSON.stringify(this.$store.getters.filtroCadastroTecnicos));
    }     
    this.fetchLista(this.filtro);
    
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 300px);
  }
</style>